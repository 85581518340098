import React from "react"
import {Link} from "gatsby"
import Tag from "./tag"

export default function Post(props) {
    const post = props.post.node;
    console.log(post);
    return (
        <Link to={post.fields.slug}>
            <div class="card mb-4">
                <div class="card-header">
                    <h5 class="card-title accent accent-padding">{post.frontmatter.title}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Posté le {post.frontmatter.date}</h6>
                </div>
                <div class="card-body">
                    <p class="card-text">{post.excerpt}</p>
                </div>
                <div class="card-footer">
                    {post.frontmatter.tags.map(tag => (
                        <Tag tag={tag}/>
                    ))}
                </div>
            </div>
        </Link>
    )
}