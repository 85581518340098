import React from "react"
import Post from "../components/post"

export default function PostList(props) {
    const posts = props.posts;
    return (
        <div>
            {posts.map(post => (
                <Post post={post} />
            ))}
        </div>
    )
}